// assets/js/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
// require('bootstrap/dist/css/bootstrap.min.css');
// require('bootstrap-icons/font/bootstrap-icons.css');
import '../css/app.scss'
import 'popper.js';
import * as bootstrap from 'bootstrap';
// import { Tooltip, Toast, Popover } from 'bootstrap';
import Sortable from 'sortablejs';
import SimpleLightbox from "simplelightbox";
// import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';


import Swiper from 'swiper/bundle';

// import * as Daterangepicker from 'daterangepicker';
require ('daterangepicker/daterangepicker.js');
const $ = require('jquery'),
    moment = require('moment');
// const routes = require('../../public/fos_js_routes.json');
// import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';





// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');


// any CSS you import will output into a single css file (app.css in this case)
// import bootstrap from 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import '../css/app.scss';
 // import Sortable from 'sortablejs';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

var reviewElements = [].slice.call(document.querySelectorAll('#review_form_stars .form-check-label'));
reviewElements.map(function (element) {
    element.addEventListener("click", function(e) {
        var selectedStar = this.previousElementSibling.value;
        document.querySelectorAll('#review_form_stars .form-check-label').forEach(function(label) {
                var iElement = label.childNodes[0];
                var radio = label.previousElementSibling;
                    iElement.classList.remove('text-light', 'bi-star', 'text-warning', 'bi-star-fill');
                if (radio.value <= selectedStar) {
                    iElement.classList.add('text-warning', 'bi-star-fill');
                } else {
                    iElement.classList.add('text-light', 'bi-star');
                }
            }
        );
    });
});

$(document).ready(function() {

    // $('.select2').selectpicker();
    $(".hide_password a").on('click', function(event) {
        event.preventDefault();
        let parent = $(this).parent().parent();
        let thisElement = $(this);
        if (parent.children('input').attr("type") == "text") {
            parent.children('input').attr('type', 'password');
            thisElement.children('i').addClass( "bi-eye-slash" );
            thisElement.children('i').removeClass( "bi-eye" );
        } else if (parent.children('input').attr("type") == "password") {
            parent.children('input').attr('type', 'text');
            thisElement.children('i').removeClass( "bi-eye-slash" );
            thisElement.children('i').addClass( "bi-eye" );
        }
    });
    $('.dates').daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: true,
        applyButtonClasses: "btn-primary text-white",
        autoUpdateInput: false,
        timePickerIncrement: 15,
        locale: {
            cancelLabel: 'Anuleaza',
            applyLabel: 'Selecteaza',
            format: 'YYYY-MM-DD hh:mm:00',
            weekLabel: 'S',
            daysOfWeek: 'Du_Lu_Ma_Mi_Jo_Vi_Sâ'.split('_'),
            monthNames: 'ianuarie_februarie_martie_aprilie_mai_iunie_iulie_august_septembrie_octombrie_noiembrie_decembrie'.split('_'),
            firstDay: 1
        },
        // startDate: moment().startOf('hour')
        // endDate: moment().startOf('hour').add(32, 'hour'),
    }).on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY-MM-DD hh:mm'));
    });

    var lightbox = new SimpleLightbox('a.gallery', { overlayOpacity: 0.95, widthRatio: 1 });

    lightbox.on('close.simplelightbox', function () {
        document.querySelector('.sl-triauto').classList.remove('active');
    });

    lightbox.on('show.simplelightbox', function () {
        if (document.querySelector('.sl-triauto')) {
            document.querySelector('.sl-triauto').remove();
        }
    });
    lightbox.on('shown.simplelightbox', function () {
        var div = document.createElement("DIV");
        var img = document.createElement("IMG");
        div.classList.add('sl-triauto');
        div.classList.add('active');
        img.classList.add('logo-silver');
        img.setAttribute('src', '/build/logo-triauto-lines.svg');
        div.append(img);
        document.querySelector('.sl-wrapper.simple-lightbox').prepend(div);
    });

    setTimeout(function() {
        document.querySelector('.toast-container').classList.add('d-none');
    }, 3000);
});

    let clonedDataTransfer = new DataTransfer();

    $('input.autocomplete, select.autocomplete').on('mousedown', function(e) {
        e.preventDefault();
        this.blur();
        window.focus();
    });

    var modalElements = [].slice.call(document.querySelectorAll('.modal.fade'));
    modalElements.map(function (modalElement) {
        modalElement.addEventListener("shown.bs.modal", function (e) {
            var filterText = document.querySelector('.modal.show .autocomplete-input')
            if (filterText) {
                filterText.addEventListener('keyup', function(j){
                    var autocompleteText = this.value.toLowerCase();
                    var textElements = document.querySelectorAll('.modal.show .autocomplete-filter .element-filter');
                    textElements.forEach(element => {
                        if (element.innerText.toLowerCase().search(autocompleteText) >= 0) {
                            element.parentElement.classList.remove('d-none');
                        } else {
                            element.parentElement.classList.add('d-none');
                        }
                    });
                });
            }
        })
    });

// var myModals = document.querySelectorAll('.modal.fade')
// myModalEl.addEventListener('hidden.bs.modal', function (event) {
//     // do something...
// })
//
//
// function filterResults() {
//     console.log('dada');
//     var filter   = Array.prototype.filter,
//     result   = document.querySelectorAll('.modal.show .autocomplete-filter a'),
//         filtered = filter.call( result, function( node ) {
//             if (node.innerText !== 'Romania') {
//                 node.classList.add('hide');
//             }
//         });
// }
// var filterText = document.querySelector('.modal.show .autocomplete-input');
// filterText.addEventListener('keydown', filterResults );



    $(document).on('click', '.list-group-item', function (e){
        e.preventDefault();
        e.stopPropagation();
        $('.county-selected').val($(this).text()).removeClass('county-selected');
        $('.btn-close').trigger('click');
    });

    var el = document.getElementById('images-area');
    if (el) {
        var sortable = Sortable.create(el, {
            // Changed sorting within list
            swapThreshold: 0.39,
            animation: 0,
            handle: "img",
            dragoverBubble: true,
            onUpdate: function (evt) {
                var array = document.querySelectorAll('#images-area img');
                clonedDataTransfer.items.clear();
                for (var i = 0; i < array.length; ++i) {
                    var imgName = array[i].getAttribute('img-name');
                    for (var ii = 0; ii < dataTransfer.items.length; ++ii) {
                        if (dataTransfer.files[ii] !== 'undefined') {
                            var imgNameDataTransfer = dataTransfer.files[ii].name;
                            if (imgName === imgNameDataTransfer) {
                                clonedDataTransfer.items.add(dataTransfer.files[ii]);
                            }
                        }
                    }
                }
                dataTransfer.items.clear();
                for (var index = 0; index < clonedDataTransfer.items.length; ++index) {
                    dataTransfer.items.add(clonedDataTransfer.files[index]);
                }
                userImage.files = dataTransfer.files;
            },
        });
    }



// var notificationStore = notifications.map(function (notification) {
//     console.log(notification);
//     var toast = new bootstrap.Toast(toastLiveExample)
//     toast.show()
// });
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-dismiss="toast"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    // var myToast = bootstrap.Toast.getInstance(myToastEl);
    // myToast.hide()
    tooltipTriggerEl.addEventListener("click", function(e) {
        var toast = new bootstrap.Toast(this.parentElement.parentElement)
        toast.hide()
        // var myToast = bootstrap.Toast.getInstance();
        // myToast.hide()
    });
});

//
// var myToastEl = document.getElementById('myToast')
// myToastEl.addEventListener('hidden.bs.toast', function () {
//     // do something...
// })

function initTooltip() {

    // LoginAndRegister - btn submit form
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {
            container: 'body'
        });
    });
}
const blockResults = document.getElementById('results');
if (blockResults !== null) {
    blockResults.addEventListener('favoriteEvent', favoriteHandler, false);
}

function favoriteHandler() {
    var iconsFavorite = [].slice.call(document.querySelectorAll(
        '.btn.btn-sm.btn-outline-light, .btn.btn-outline-light.border-0, .btn-fav'
    ));

    iconsFavorite.map(function (element) {
        if (!element.classList.contains('was-init')) {
            element.classList.add('was-init');
            element.addEventListener("click", function(e) {
                var xhr = new XMLHttpRequest();
                var isFavorite = this.children[0].classList.contains('bi-heart-fill');

                this.children[0].classList.replace(
                    (isFavorite ? 'bi-heart-fill' : 'bi-heart'),
                    (isFavorite ? 'bi-heart': 'bi-heart-fill'),
                );

                if (!this.classList.contains('btn-fav') && this.children.hasOwnProperty(1)) {
                    this.children[1].innerHTML = this.children[1].textContent.replace(
                        (isFavorite ? 'Anunț salvat' : 'Salvează anunțul'),
                        (isFavorite ? 'Salvează anunțul': 'Anunț salvat'),
                    );
                }
                xhr.onreadystatechange = function(resonse) {
                    if (this.readyState === 4 && this.status === 200) {
                        if (this.responseURL.search('login') >= 0) {
                            var myModal = new bootstrap.Modal(document.getElementById('mustLogin'), {
                                keyboard: false
                            });
                            myModal.show();
                        }

                    }

		            if (this.readyState === 4 && this.status === 401) {
                            var myModal = new bootstrap.Modal(document.getElementById('mustLogin'), {
                                keyboard: false
                            });
                            myModal.show();
                    }
                };
                xhr.open('POST', this.getAttribute('data-url'), true);
                xhr.setRequestHeader("Content-Type", "application/json");
                var data = JSON.stringify({"isFavorite": this.children[0].classList.contains('bi-heart'), 'user_guest':localStorage.getItem('user_guest')});
                xhr.send(data);
                if (this.children[0].classList.contains('bi-trash3')) {
                    window.location.reload();
                }
            });
        }
    });
}
favoriteHandler();

function selectedTab () {
    const hashUrl = window.location.hash;
    const hasElement = document.querySelector('nav.nav a[data-bs-target="' + hashUrl + '"]');
    if (hasElement) {
        var selectedTab = new bootstrap.Tab(hasElement)
        selectedTab.show();
    }
}



var swiperList = [].slice.call(document.querySelectorAll('.swiper'))
swiperList.map(function (swiperElement) {
    var swiperId = swiperElement.getAttribute('id');
    const swiperObj = new Swiper('#'+swiperId, {
        loop: true,
        pagination: {
            el: '#swiper-pagination-'+swiperId,
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '  m-2 p-2 rounded-circle d-inline-flex"></span>';
            }
        },
        navigation: {
            nextEl: '#swiper-next-'+swiperId,
            prevEl: '#swiper-prev-'+swiperId,
        },
        breakpoints: {
            // when window width is >= 280px
            280: {
                slidesPerView: 2
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3
            },
            // when window width is >= 640px
            800: {
                slidesPerView: 5
            }
        },
        on : {
            afterInit : function () {
                var swiperElements = [].slice.call(document.querySelectorAll('.swiper'))
                swiperElements.map(function (element) {
                    element.classList.remove('d-none');
                });
            }
        }
    });
});

selectedTab();



// var reviewChange = function(event) {
//     console.log(event);
//     // output.innerHTML = "";
//     for (const [position, file] of Object.entries(event.target.files)) {
//         // appendImages(file, position);
//         // dataTransfer.items.add(file);
//     }
//
//     userImage.files = dataTransfer.files;
// };




initTooltip();
